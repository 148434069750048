import { getProviders, signIn } from 'next-auth/client';
import { AppProviders } from 'next-auth/providers';
import {
  Button,
  Flex,
  Text,
  Paper,
  useMantineTheme,
} from '@revel-transit/revel-components-internal';
import * as Icons from '@revel-transit/revel-icons';
import { requirePageAuth } from '../../../lib/auth';

type LoginAuthPageProps = {
  providers: AppProviders;
};

const LoginScreen = ({ providers }: LoginAuthPageProps) => {
  const [googleProvider] = Object.values(providers);
  const theme = useMantineTheme();

  return (
    <Flex h="100%" align="center" justify="center" p="md">
      <Paper
        w={400}
        withBorder
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '2rem',
          padding: '2rem',
        }}
      >
        <Icons.RevelLogoText fill={theme.colors.brand[4]} height={48} width="auto" />
        <Text fw={700} component="h1" fz={32}>
          Driver Ops
        </Text>
        <Button
          variant="filled"
          size="lg"
          color="brand.4"
          onClick={() => signIn(googleProvider.id, { callbackUrl: '/' })}
          sx={{ maxWidth: '100%' }}
        >
          Sign in with {googleProvider.name}
        </Button>
      </Paper>
    </Flex>
  );
};

export default LoginScreen;

export const getServerSideProps = requirePageAuth(async (_: any, session: any) => {
  if (session) {
    return {
      props: { session },
      redirect: {
        destination: '/',
        permanent: false,
      },
    };
  }

  const providers = await getProviders();
  return {
    props: { providers },
  };
});
